/* Page */

* {
    box-sizing: border-box;
    font-weight: 500;
    letter-spacing: 1px;
}

#page {
    width: 100%;
    height: 100%;
}

.hidden {
    visibility: hidden;
}

.menu-btn {
    background-color: #f7f7f700;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 45px;
    position: fixed;
    right: 30px;
    top: 15px;
    z-index: 1;

}

.blue-text {
    color: #0062cc;
    font-weight: 600;
}

.line {
    content: "";
    width: 50px;
    height: 1px;
    background: #333;
    position: relative;
    top: 15px;
}


/* Sidebar */
aside {
    position: fixed;
    height: 100%;
    width: 105px;
    background-color: #1a409a;
    color: white;
    transition: width 0.4s ease-out;
    z-index: 1;
    transition: background-color 0.3s ease-in-out;
    transition: width 0.4s ease-in-out;
}

aside:hover {
    background-color: #0062cc;
    transition: background-color 0.3s ease-in-out;
    transition: width 0.4s ease-in-out;
    width: 200px;
}

.navbar {
    /* transition: transform 0.3s ease-in-out; */
    transform: translateX(-100%);
}

.navbar--visible {
    transform: translateX(0);
  }

nav {
    height: 100%;
}

#nav-bar-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0;
}

aside ul {
    list-style-type: none;
}

#nav-bar-list i {
    font-size: 25px;
    margin-right: 10px;
}

#nav-bar-list a {
    color: white;
}

.nav-icons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 310px;
    padding: 4.5px 0 7px 40px;
}

.nav-icons li {
    display: flex;
    align-items: center;
}

.nav-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 310px;
    padding: 0 20px;
}

.nav-buttons a {
    text-decoration: none;
    font-size: 12px;
}

.nav-buttons a li {
    
    transition-duration: 0.25s;
    border-radius: 6px;
    padding-left: 20px;
    padding-bottom: 3.5px;
    height: 50px;
    display: flex;
    align-items: center;
}

.nav-buttons li:hover {
    box-shadow: 0 14px 28px rgb(0 0 0 / 20%);
    background-color: #5e9ff3c6;
    
}

.nav-buttons li a {
    display: flex;
    text-decoration: none;
    font-size: 12px;
}

.nav-buttons li a i {
    margin-right: 10px; 
}

.scroll-down-container {
    display: flex;
    position: fixed;
    left: 38px;
    bottom: 1%;
    width: 124px;
}

.arrow i {
    font-size: 30px !important; 
}

.scroll-down-container p {
    font-size: 10px;
    margin-top: 16px;
}



/* Wrappers */

.outer-wrapper {
    width: calc(100% - 90px);
    margin-left: 90px;
    height: 100%;
}

.inner-wrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 33px;
    height: 100%;
}

/* Home */

header {
    display: flex;
    height: 100vh;
}

#img-container {
    width: 80%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 170px;
}

#img-container img {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    box-shadow: 12px 0 #c8cff1, 24px 0 #d6dcf3, 36px 0 #e6eaf5;
}

#description-container {
    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: 170px;
}

.description {
    margin: 0;
}

.name {
    font-size: 30px;
}

#socials-resume {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#resume-button {
    display: inline-block;
    padding: 10px 40px;
    background-color: #1a409a;
    color: #fff;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1rem;
    margin-right: 30px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 20%);
}

#resume-button:hover {
    background-color: #0062cc;
    transition: background-color 0.3s ease-in-out;
  }

.social-container {
    width: 45px;
    margin-left: 20px;
    border-radius: 6px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    border: 1px solid #eee;    
    box-shadow: 0px 4px 6px rgb(134 151 168 / 10%);
}

.social-container i {
    font-size: 22px;
    color: #1a409a;
}

.bi-linkedin:hover::before, .bi-github:hover::before {
    color: #0062cc;
    transition: color 0.3s ease-in-out;
}

/* Main */

main {
    width: 100%;
}

section {
    padding: 80px 0;
}

/* About */

.about-me-container {
    background-color: white;
    border-radius: 50px;
    padding: 48px;
    width: 95%;
    margin: auto;
}

.about-me {
    font-size: 1.25rem;
}

.about-head {
    padding: 40px 0;
}

/* Skills */

.skills-head {
    padding: 40px 0;
}

.skills-grid {
    background-color: white;
    border-radius: 50px;
    padding: 48px;
    width: 95%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16.6%, 1fr));
    grid-template-rows: 1fr 1fr;
    row-gap: 50px;
    justify-items: center;
}

.skill-container {
    width: 85px;
    height: 85px;
    background-color: white;
    border-radius: 15px;
    border: rgba(233, 232, 232, 0.761) 1px solid;
    box-shadow: 0px 6px 7px rgb(134 151 168 / 10%);
    transition: all 0.3s ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 12px;
}

.skill-container i {
    font-size: 45px;
}

.skill-container p {
    margin: 0;
    padding: 10px;
    font-size: 12px;
}
.skill-container img {
    width: 35px;
    height: 35px;
}

.skill-container:hover {
    box-shadow: 0 14px 28px rgb(0 0 0 / 20%);
}

#bootstrap-container p {
    padding-bottom: 8px;
}

#postgresql-container p {
    font-size: 10.5px;
}

#react-container img {
    margin-top: 3px;
    margin-bottom: 1px;
    height: 32px;
}

#rails-container img {
    width: 75px;
    height: 50px;
}

#terminal-container img {
    width: 70px;
}

/* Projects */

.projects-container {
    background-color: white;
    border-radius: 50px;
    padding: 38px;
    width: 95%;
    margin: auto;
}

.project-box {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin: 20px 0;
    border-radius: 15px;
    box-shadow: 0px 14px 28px rgb(134 151 168 / 8%);
    transition: all 0.3s ease-in;
    border: 1px solid #eee;
}

.project-box:hover {
    box-shadow: 0 14px 28px rgba(79, 75, 75, 0.134);
}

.project-img-container {
    display: flex;
}

.project-img-container img {
    border-radius: 15px;
    border: 1px solid #eee;
    width: 100%;
    justify-content: center;
}

.project-description-container {
    margin-left: 30px;
    margin-top: 15px;
}

.project-description-container h1 {
    margin: 0;
}

.project-description-container h4 {
    margin: 10px 0 25px 0;
}

.project-description-container ul {
    padding-left: 13px;
}

.project-link-container a {
    padding-right: 30px;
    font-size: 18px;
}

/* Contact */

.contact-head span, .skills-head span, .about-head span, .projects-head span  {
    margin-left: 80px;
    font-size: 11px;
}

.contact-container {
    background-color: white;
    border-radius: 50px;
    padding: 48px;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

.email-me-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 20px auto;
    border-radius: 15px;
    box-shadow: 0px 14px 28px rgb(134 151 168 / 8%);
    transition: all 0.3s ease-in;
    border: 1px solid #eee;
}

.email-me-container:hover {
    box-shadow: 0 14px 28px rgba(79, 75, 75, 0.134);
}

.email-icon-container {
    display: flex;
    border-radius: 6px;
    width: 60px;
    height: 60px;
    color: #82c690;
    background-color: #F3F9F4;
    justify-content: center;
    align-items: center;
}

.email-me-container a {
    color: black;
}

.email-me-container i {
    font-size: 27px;
}

#my-email {
 margin-top: 25px;
}

.email-me-container p {
    margin: 5px 0;
}

.contact-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 25px;
    margin-bottom: 35px;
}

.form-input {
    padding: 15px;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    border: 1px solid #eee;
}

#subject-input, #message-input {
    grid-column: 1 / 3;
}

#form-button {
    padding: 10px 40px;
    background-color: #1a409a;
    color: #fff;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1rem;
    margin-right: 30px;
    box-shadow: 0 14px 28px rgb(0 0 0 / 20%);
    border: 1px solid #eee;
    width: 120Fpx;
    margin: auto;
    cursor: pointer;
}

#form-button:hover {
    background-color: #0062cc;
    transition: background-color 0.3s ease-in-out;
}

/* MAX WIDTH 1100px */
@media (max-width: 1100px) {
    .project-box {
        padding: 10px;
        font-size: 13px;
    }

    .project-description-container h4{
        margin-bottom: 20px
    }
}

/* MAX WIDTH 990px */
@media (max-width: 990px) {
    .outer-wrapper {
        width: 100%;
        margin-left: 0;
        height: 100%;
    }

    aside {
        background-color: #0062cc;
        transition: background-color 0.3s ease-in-out;
        transition: width 0.4s ease-in-out;
        width: 200px;
    }

    .navbar {
        transition: transform 0.3s ease-in-out;
    }

    header {
        flex-direction: column;
        height: auto;
        padding-bottom:80px;
    }

    #img-container {
        width: 100%;
        top: 80px;
    }

    #description-container {
        height: 50%;
        margin-top: 50px;
        top: 80px;
        width: 100%;
        text-align: center;
        align-items: center;
    }

    #description-container p {
        width: 75%;
    }

    .inner-wrapper {
        padding: 0 10px;
    }

    .skills-grid {
        row-gap: 40px;
        grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
    }

    #name-input, #email-input {
        grid-column: 1 / 3;
    }
}

/* MAX WIDTH 767px */
@media (max-width: 767px) {
    #img-container img {
        width: 250px;
        height: 250px;
    }

    .about-me-container {
        padding: 0;
        width: 100%;
        margin: 0;
        background-color: #f7f7f7;
    }

    .about-me-container article {
        font-size: 14px;
    }

    section {
        padding: 40px 0;
    }

    .skills-grid {
        padding: 0;
        width: 100%;
        margin: 0;
        background-color: #f7f7f7;
        row-gap: 10px;
        grid-template-columns: repeat(auto-fit, minmax(96px, 1fr));
    }

    .projects-container {
        padding: 0;
        width: 100%;
        margin: 0;
        background-color: #f7f7f7;

    }

    .project-box {
        flex-direction: column;
    }

    .project-description-container {
        margin-left: 10px;
        margin-top: 25px;
    }

    .project-img-container {
        max-width: 500px;
        margin: 0 auto;
    }

    .project-img-container img {
        margin: 0 auto;
    }

    .contact-container {
        padding: 0;
        width: 100%;
        margin: 0;
        background-color: #f7f7f7;
    }

    .email-me-container {
        width: 100%;
    }

    .menu-btn {
        right: 0px;
        top: 10px;
    }

}

/* MAX WIDTH 440px */
@media (max-width: 440px) {
    .project-img-container img {
        width: 100%;
        height: auto;
    }

    #img-container img {
        width: 100%;
        height: auto;
    }

    .menu-btn {
        right: -25px;
    }
}

/* MAX WIDTH 400px */
@media (max-width: 400px) {
    header {
        padding: 40px 0;
    }
    
}
  